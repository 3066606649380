import React from "react";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import Image from "../components/Image";
import Button from "../components/Button";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../containers/Layout";

// bg-gradient-to-r from-lilac to-lavendar 
export default function about() {
  return (
    <Layout title='DMC | About'>
      <div className="flex md:flex-row flex-col justify-center w-4/5 my-24 md:h-108 mx-auto md:relative h-48">
        <div className="md:w-1/2 rounded left-36 shadow-xl rounded-2xl md:animate-slide-left md:absolute md:m-0 my-2">
          <Image alt="" src="about-hero.jpg" rounded={true} />
        </div>
        <div className="md:w-2/5 p-6 bg-white md:right-20 md:top-20 shadow-xl rounded-xl md:animate-slide-right md:absolute ">
          <h3>About Dentsu Marketing Cloud</h3>
          <p>
            The DMC product suite has sophisticated products that deliver
            granular audience insights, adopting privacy by design in an action
            oriented platform with cross platform performance optimization
            towards various goals. The Dentsu Marketing Cloud is currently
            deployed in multiple markets across the globe for 1500+ clients,
            garnering many Global Awards.
          </p>
          <p>
            Dentsu International is the first global agency group to be badged
            as a Facebook Marketing Partner via the dentsu Data Sciences team,
            India.
          </p>
          <div className="flex w-full">
            <StaticQuery
              query={graphql`
                query {
                  file(
                    relativePath: {
                      eq: "Onboarding Document DMC Facebook [updated 07-09-21].pdf"
                    }
                  ) {
                    id
                    publicURL
                    name
                  }
                }
              `}
              render={(data) => {
                console.log(data, "download");
                return (
                  <a
                    target={`blank`}
                    rel="noopener noreferrer"
                    href="https://blog.dentsumarketing.cloud/"
                  >
                    <Button type="primary">
                      {/* <Image alt="" src="download.png" width={20} /> */}
                      <span className="ml-2">Know More</span>
                    </Button>
                  </a>
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="md:w-2/3 mx-auto my-24 text-center uppercase 
      brush-stroke-banner-6
       p-20">
        <h5>Our Purpose</h5>
        <h3 className="w-3/4 mx-auto">
          "Delivering data led intelligence to solve for marketing effectiveness
          through a self serve product."
        </h3>
      </div>
      <div className="w-4/5 md:w-1/2 mx-auto my-24 text-center uppercase">
        <h3 className="my-14">A cookie-less solution that is</h3>
        <div className=" mx-auto flex md:flex-row flex-col justify-evenly">
          <div className="md:w-1/3 p-3">
            <Image alt="" src="001-microscope.png" width={100} />
            <p className="my-4">Audience Focused</p>
          </div>
          <div className="md:w-1/3 p-3 ">
            <Image alt="" src="002-call-to-action.png" width={100} />
            <p className="my-4">Action Oriented</p>
          </div>
          <div className="md:w-1/3 p-3 ">
            <Image alt="" src="003-privacy.png" width={100} />
            <p className="my-4">Privacy Led</p>
          </div>
        </div>
      </div>
      <div className="md:w-2/3 mx-auto my-24 text-center">
        <div className="mx-auto flex flex-wrap md:flex-row flex-col md:justify-center items-center md:items-stretch justify-center">
          <div className="relative h-64 bg-transparent flex flex-col w-72 bg-cover bg-white shadow-xl my-4 mx-10 ml-1 rounded-xl text-left">
            <div className="absolute h-full rounded-xl w-full t-0 l-0 bg-black/60 p-5 text-white">
              <h5>Transparent</h5>
              <ul>
                <li>Moving away from the Blackbox</li>
                <li>In-depth Reporting on Campaigns</li>
                <li>Clarity on optimizations suggested</li>
              </ul>
            </div>
          </div>
          <div className="relative h-64 flex flex-col w-72 bg-cover bg-actionable shadow-xl my-4 mx-10 ml-1 rounded-xl text-left">
            <div className="absolute h-full rounded-xl w-full t-0 l-0 bg-black/60 p-5 text-white">
              <h5>Actionable</h5>
              <ul>
                <li>
                  Every screen has an easy-to-understand analysis and action
                  button
                </li>
                <li>
                  One click to get recommendations and activate on analysis.
                </li>
              </ul>
            </div>
          </div>
          <div className="relative h-64 bg-privacy flex flex-col w-72 bg-cover bg-white shadow-xl my-4 mx-10 ml-1 rounded-xl text-left">
            <div className="absolute h-full rounded-xl w-full t-0 l-0 bg-black/60 p-5 text-white">
              <h5>Privacy first</h5>
              <ul>
                <li>In compliance with global privacy laws like GDPR, CCPA.</li>
                <li>
                  Dedicated Data Governance Officer to ensure best practices
                </li>
                <li>Future-proofed with cohort-based activations</li>
              </ul>
            </div>
          </div>
          <div className="relative h-64 bg-customize flex flex-col w-72 bg-cover bg-white shadow-xl my-4 mx-10 ml-1 rounded-xl text-left">
            <div className="absolute h-full rounded-xl w-full t-0 l-0 bg-black/60 p-5 text-white">
              <h5>Customizable</h5>
              <ul>
                <li>
                  Multiple marketing platforms with varied methods of
                  measurement
                </li>
                <li>
                  Difficult to integrate brand's offline and online data sets
                </li>
              </ul>
            </div>
          </div>
          <div className="relative h-64 bg-trust flex flex-col w-72 bg-cover bg-white shadow-xl my-4 mx-10 ml-1 rounded-xl text-left">
            <div className="absolute h-full rounded-xl w-full t-0 l-0 bg-black/60 p-5 text-white">
              <h5>Trustworthy</h5>
              <ul>
                <li>Ability to add new features based on client needs</li>
                <li>Easily integrate new data sources</li>
                <li>Works seamlessly with common marketing platforms</li>
                <li>
                  In house MarTech product with a dedicated team of 30+
                  engineers
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
